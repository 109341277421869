import React, { useEffect, useState } from 'react';
import Input from './Input';
import { useAppDispatch } from '../redux/hooks';
import { actions } from '../redux/features/data/quipSlice';

interface ControlWidgetProps {
  showFilter?: boolean; // Показать/скрыть фильтр
  onFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Callback для изменения фильтра
  onAddClick: () => void; // Callback для добавления нового элемента
  addButtonLabel?: string; // Текст кнопки добавления (например, "Add Tag", "Add Joke")
  onImportClick?: () => void; // Callback для импорта (настраиваемый)
  showImport?: boolean; // Показать/скрыть кнопку импорта
}

const ControlWidget: React.FC<ControlWidgetProps> = ({
  showFilter = true,
  onFilterChange,
  onAddClick,
  addButtonLabel = 'Add',
  onImportClick,
  showImport = false,
}) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(actions.setSearchTerm(searchQuery));
  }, [searchQuery]);

  return (
    <div className="control-widget">
      <div className="control-widget-actions">
        {showFilter && (
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="input-search"
          />
        )}

        <button onClick={onAddClick} className="control-widget-add-btn">
          {addButtonLabel}
        </button>

        {showImport && onImportClick && (
          <button onClick={onImportClick} className="control-widget-import-btn">
            Import
          </button>
        )}
      </div>
    </div>
  );
};

export default ControlWidget;
