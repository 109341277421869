export const jokeColumns = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string',  // Простой текст
  },
  {
    id: 2,
    name: 'Joke',
    key: 'text',
    type: 'string',  // Текст шутки
  },
  {
    id: 3,
    name: 'Tags',
    key: 'tags',
    type: 'array',  // Текст шутки
  },
  {
    id: 4,
    name: 'Created At',
    key: 'createdAt',
    type: 'string',  // Дата создания
  },
];
