import { Quip } from "../types/Quip";
import { client, client2 } from "./client";

export const getQuips = async (object: { searchTerm: string, page: number, pageSize: number }) => {
  const response = await client.post('/quip/get', object);
  return response.data;
}

export const createQuip = async (object: Quip) => {
  const response = await client.post('/quip/create', object);
  return response.data;
}

export const importQuips = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await client2.post('/quip/import', formData);
  return response.data;
}

export const updateQuip = async (object: Quip) => {
  const response = await client.post('/quip/edit', object);
  return response.data;
}

export const removeQuip = async (id: number) => {
  const response = await client.delete(`/quip/${id}`);
  return response.data;
}
