import { configureStore } from '@reduxjs/toolkit';
import keyAnswerReducer from './features/data/quipSlice';
// import authReducer from './features/auth/authSlice';

export const store = configureStore({
  reducer: {
    keyAnswer: keyAnswerReducer,
    // auth: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
