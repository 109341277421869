import React, { useState, useEffect } from 'react';
import TableView from '../components/TableView';
import ControlWidget from '../components/ControlWidget';
import Modal from '../components/Modal';
import { getJokes, createJoke } from '../api/jokes';
import { jokeColumns } from '../constants/jokesColumns';
import Textarea from '../components/Textarea';
import Button from '../components/Button';

const Jokes = () => {
  const [jokes, setJokes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newJoke, setNewJoke] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchJokes();
  }, []);

  const fetchJokes = async () => {
    setLoading(true);
    setError('');

    try {
      const data = await getJokes();
      setJokes(data);
    } catch (error) {
      setError('Failed to fetch jokes');
    } finally {
      setLoading(false);
    }
  };

  const handleAddJoke = () => {
    setModalOpen(true);
  };

  const handleSaveJoke = async () => {
    await createJoke(newJoke);
    setModalOpen(false);
    setNewJoke('');
    fetchJokes(); // Обновляем список шуток после создания
  };

  return (
    <div>
      <div className="widget-container">
        <ControlWidget
          onAddClick={handleAddJoke}
          addButtonLabel="Add Joke"
          showFilter={true}
          showImport={true}
          onImportClick={() => {}}
        />
      </div>

      <div className="data-table-container">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <TableView columns={jokeColumns} data={jokes} />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Add New Joke"
      >
        <Textarea
          placeholder="Enter your joke here..."
          value={newJoke}
          onChange={(e) => setNewJoke(e.target.value)}
        />
        <Button text="Save" onClick={handleSaveJoke} />
      </Modal>
    </div>
  );
};

export default Jokes;
