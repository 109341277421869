import { client } from './client';

// Получение всех шуток
export const getJokes = async () => {
  const response = await client.get('/jokes');
  return response.data;
};

// Создание новой шутки
export const createJoke = async (text: string) => {
  const response = await client.post('/jokes', { text });
  return response.data;
};

// Обновление шутки
export const updateJoke = async (id: number, text: string) => {
  const response = await client.put(`/jokes/${id}`, { text });
  return response.data;
};

// Удаление шутки
export const deleteJoke = async (id: number) => {
  const response = await client.delete(`/jokes/${id}`);
  return response.data;
};
