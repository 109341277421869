import React, { useState, useEffect } from 'react';
import TableView from '../components/TableView';
import ControlWidget from '../components/ControlWidget';
import Modal from '../components/Modal';
import { getTags, createTag } from '../api/tags';
import { tagColumns } from '../constants/tagsColumns';
import Input from '../components/Input';
import Button from '../components/Button';

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newTag, setNewTag] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    setLoading(true);
    setError('');

    try {
      const data = await getTags();
      setTags(data);
    } catch (error) {
      setError('Failed to fetch tags');
    } finally {
      setLoading(false);
    }
  };

  const handleAddTag = () => {
    setModalOpen(true);
  };

  const handleSaveTag = async () => {
    await createTag(newTag);
    setModalOpen(false);
    setNewTag('');
    fetchTags();
  };

  return (
    <div>
      <div className="widget-container">
        <ControlWidget
          onAddClick={handleAddTag}
          addButtonLabel="Add Tag"
          showFilter={false}
        />
      </div>

      <div className="data-table-container">
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <TableView columns={tagColumns} data={tags} />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Add New Tag"
      >
        <Input
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="Enter tag..."
          withTagIcon={true}
        />
        <Button text="Save" onClick={handleSaveTag} />
      </Modal>
    </div>
  );
};

export default Tags;
