export const tagColumns = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string',  // Простой текст
  },
  {
    id: 2,
    name: 'Tag',
    key: 'tag',
    type: 'string',
  },
  {
    id: 3,
    name: 'Created At',
    key: 'createdAt',
    type: 'string',  // Дата создания
  },
];
