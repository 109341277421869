import React from 'react';
import ToggleSwitch from './ToggleSwitch';
import { Column } from '../types/Column';

interface TableViewProps {
  columns: Column[];
  data: any[];
}

const TableView: React.FC<TableViewProps> = ({ columns, data }) => {

  const renderCell = (column: Column, row: any) => {
    switch (column.type) {
      case 'string':
        return <td>{row[column.key] || '-'}</td>;

      case 'boolean':
        return (
          <td>
            <ToggleSwitch
              isActive={row[column.key]}
              onToggle={(newState) => column.toggleFunction && column.toggleFunction(row.id, newState)}
            />
          </td>
        );

      case 'array':
        return (
          <td>
            {/* {row[column.key] && row[column.key].length > 0 ? (
              <button onClick={() => console.log(row[column.key])}>Показать</button>
            ) : (
              '-'
            )} */}
            {
              row[column.key].map(({ text }: { text: string }) => (
                <div>{text}</div>
              ))
            }
          </td>
        );

      default:
        return <td>{row[column.key] || '-'}</td>;
    }
  };

  return (
    <table className="data-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.id}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            {columns.map((column) => (
              <React.Fragment key={column.id}>
                {renderCell(column, row)}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableView;
