import { client } from './client';

// Получение всех тегов
export const getTags = async () => {
  const response = await client.get('/tags');
  return response.data;
};

// Создание нового тега
export const createTag = async (newTag: string) => {
  const response = await client.post('/tags', { newTag });
  return response.data;
};

// Обновление тега
export const updateTag = async (id: number, newTag: string) => {
  const response = await client.put(`/tags/${id}`, { newTag });
  return response.data;
};

// Удаление тега
export const deleteTag = async (id: number) => {
  const response = await client.delete(`/tags/${id}`);
  return response.data;
};
